import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import picicon from '../images/iconinfo.png'

const NotFoundPage = () => (
  <Layout>
    <div className="infoPage">
      <h1><Link to="/">404 - NOT FOUND</Link></h1>
      <p>
        <span className="image infoImage">
          <Link to="/"><img src={picicon} alt="Back" /></Link>
        </span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
